import { computed, ref } from 'vue'
import Cookies from 'js-cookie'
let init

export const useUserStore = () => {
  if (init) { return init }
  const token = ref(Cookies.get('user-token') || '')
  const userInfo = ref(null)

  const setToken = (tokenArg: string) => {
    token.value = tokenArg
  }

  const getToken = computed(() => token.value)

  const clearData = () => {
    token.value = ''
    userInfo.value = null
  }

  const store = ref({
    token,
    setToken,
    getToken,

    clearData
  })

  init = store.value as typeof store.value
  return store.value
}
